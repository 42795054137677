import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import {
  ACTIVE_NETWORK,
  mlmContractAddress,
  NetworkDetails,
} from "src/constants";
import { toast } from "react-toastify";
import { getContract, getWeb3Obj } from "src/utils";
import MLMABI from "../../src/ABI/MLMABI.json";
import Web3 from "web3";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("walletName");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [userInfo, setUserInfo] = useState([]);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [ownerAccount, setOwnerAccount] = useState("");
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const connectToWallet = () => {
    const connector = injected;
    window.sessionStorage.removeItem("walletName");
    window.sessionStorage.setItem("walletName", "METAMASK");
    window.sessionStorage.setItem("isLogin", true);
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log("error", error.message);
        // toast.error(error.message);
        // activate(connector);
      }
    });
  };
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      // toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };
  const disconnectWalletHandler = async () => {
    try {
      window.sessionStorage.removeItem("walletName");
      window.sessionStorage.setItem("isLogin", false);
      deactivate();
      toast.success("You have been disconnected successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  let data = {
    userLoggedIn: isLogin,
    userInfo,
    ownerAccount,
    yourWalletBalance,
    isLoadingDetails,
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => connectToWallet(),
    disconnectWallet: () => disconnectWalletHandler(),
    getParticularUserDataHandler: () => getParticularUserDataHandler(),
    getUserbalce: () => getUserbalce(),
  };

  const getParticularUserDataHandler = async () => {
    try {
      setIsLoadingDetails(true);
      const web3 = await getWeb3Obj();
      const contractObj = getContract(
        mlmContractAddress,
        MLMABI,
        library,
        account
      );
      const adminAccount = await contractObj.owner();
      const userInfoFun = await contractObj.userInfo(account);
      const userDetailsFun = await contractObj.userDetails(account);
      let obj = {
        isExist: userInfoFun[0],
        id: userInfoFun[1]?.toString(),
        lastTimeStamp: userInfoFun[2]?.toString(),
        currentLevel: userInfoFun[3]?.toString(),
        totalReferralAmount: web3.utils.fromWei(userInfoFun[4]?.toString()),
        pendingReferralAmount: web3.utils.fromWei(userInfoFun[5]?.toString()),
        referralLevelAmount: userDetailsFun[6],
        referralLevelCount: userDetailsFun[7],
      };
      setOwnerAccount(adminAccount);
      setUserInfo(obj);
      setIsLoadingDetails(false);
    } catch (error) {
      console.log(error);
      setIsLoadingDetails(false);
    }
  };
  const getUserbalce = async () => {
    setYourWalletBalance("");
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  useEffect(() => {
    if (account) {
      getParticularUserDataHandler();
      getUserbalce();
    }
  }, [account]);

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);
  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("walletName");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
